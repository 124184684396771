import React from 'react'
import './portfolio.css'
import portfImg1 from '../../assets/projects/undulateMusicScreens/1undulateTile.png'
import portfImg2 from '../../assets/projects/doggolandScreens/2doggolandTile.png'
import portfImg3 from '../../assets/projects/lyricalGeniusScreens/3lyricalGeniusTile.png'
import portfImg4 from '../../assets/projects/amazonReplicaScreens/4amazonTile.png'
import demoDoggoImg from '../../assets/projects/doggolandScreens/homenormal1.png'
import demoAmazonImg from '../../assets/projects/amazonReplicaScreens/amazonHeader.png'

const data = [
  {
    id: 1,
    image: portfImg1,
    title: 'Undulate Music Mobile App',
    github: 'https://gitlab.com/abijacob/undulatemusic',
    demo: 'https://drive.google.com/file/d/1d5w4jDfZtSHOEldkorZmNH2uKa6_phc6/view?usp=sharing',
  },
  {
    id: 2,
    image: portfImg2,
    title: 'Doggoland Dog Breed Portfolio',
    github: 'https://github.com/abijacob/Doggoland-Dog-Breed-Information-WebApplication',
    demo: demoDoggoImg,
  },
  {
    id: 3,
    image: portfImg3,
    title: 'Lyrical Genius Mobile App',
    github: 'https://github.com/abijacob/lyricalgenius',
    demo: 'https://drive.google.com/file/d/1yuSbjdXkvBpr3yNgcqzbTIuBlEOiwhFn/view?usp=sharing',
  },
  {
    id: 4,
    image: portfImg4,
    title: 'Amazon Website Recreated',
    github: 'https://github.com/abijacob/Amazon-Website-Replica',
    demo: demoAmazonImg,
  }
]

const portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Projects</h2>

      <div className="container portfolio__container">
         {
         data.map(({id, image, title, github, demo} ) => {
            return (
              <article key={id} className='portfolio__item'>
          <div className="portfolio__item-image">
             <img src={image} alt ={title}/>
            </div>
            <h3>{title}</h3>
            <div className="portfolio__item-cta">
              <a href={github} className='btn' target='_blank' rel="noreferrer">GitHub</a>
              <a href={demo} className='btn btn-primary' target='_blank' rel="noreferrer">Live Demo</a>
            </div>
        </article>
            )
         })
        }
      </div>
    </section>
  )
}

export default portfolio