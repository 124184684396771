import React from 'react'
import { FaLinkedin } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";


const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href='https://www.linkedin.com/in/abi-jacob/' target='_blank' rel ="noreferrer"><FaLinkedin /></a>
        <a href='https://github.com/abijacob' target='_blank' rel ="noreferrer"><FaGithub/></a>
    </div>
  )
}

export default HeaderSocials