import React from 'react'
import './contact.css'
import { HiOutlineMail } from "react-icons/hi";
import { BsLinkedin } from "react-icons/bs";
import { FaGithub } from "react-icons/fa";

const contact = () => {
  return (
    <section id='contact'>
      <h5>Get in Touch</h5>
      <h2>Contact me</h2>

      <div className="container contact__container">
        <div className="contact__options">
            <article className="contact__option">
              <HiOutlineMail className='contact__option-icon' />
              <h4>Email</h4>
              <h5>abijacob92@gmail.com</h5>
              <a href="mailto:abijacob92@gmail.com">Send an Email</a>
            </article>

            <article className="contact__option">
              <BsLinkedin className='contact__option-icon' />
              <h4>LinkedIn</h4>
              <h5>linkedin.com/in/abi-jacob/</h5>
              <a href="https://www.linkedin.com/in/abi-jacob/">Connect on LinkedIn</a>
            </article>
             
            <article className="contact__option">
              <FaGithub className='contact__option-icon' />
              <h4>GitHub</h4>
              <h5>github.com/abijacob</h5>
              <a href="https://github.com/abijacob">View my projects</a>
            </article>
        </div>
        {/* END OF CONTACT INFORMATION */}
        <form action=""></form>
      </div>
    </section>
  )
}

export default contact