import React from 'react'
import './footer.css'
import { BsLinkedin } from "react-icons/bs";
import { FaGithub } from "react-icons/fa";

const footer = () => {
  return (
    <footer>
      <a href="#" className='footer__logo'>Abi Jacob</a>

      <div className="footer__socials">     
        <a href="https://www.linkedin.com/in/abi-jacob/"><BsLinkedin /></a>
        <a href="https://github.com/abijacob"><FaGithub /></a>
      </div>

      <ul className="permalinks">
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#portfolio">Projects</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>   

      <div className="footer__copyright">
        <small>&copy; Abi Jacob . All rights reserved.</small>
      </div>
    </footer>
  )
}

export default footer