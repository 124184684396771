import React from 'react'
import './about.css'
import ME2 from '../../assets/abiProfFin.jpg'
import { FaLaptopCode } from "react-icons/fa6";
import { GoProjectSymlink } from "react-icons/go";


const about = () => {
  return (
    <section id='about'>
      <h5>Get to Know</h5>
      <h2>About Me</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME2} alt="About Image"/>
          </div>
        </div>

        <div className="about__content">
        
        <p>I am a dedicated and innovative Computer Science graduate student at California State University, Chico, with a robust background in software development. My expertise spans multiple programming languages, including JavaScript, Java, Swift, C++, Python, and SQL, as well as front-end and back-end technologies. I am proficient in tools like Git, VS Code, Xcode, Android Studio, Firebase, and Google Cloud, with additional skills in Flutter and React.<br/><br/>   My technical skills encompass frontend UI development and backend development, enabling me to build comprehensive full-stack applications. I actively push my code to GitHub to track progress and maintain a strong online presence. <br/><br/>I am enthusiastic about collaborating on innovative projects and leveraging my skills to contribute to groundbreaking developments in a dynamic and forward-thinking environment. I am open to opportunities across various sectors in the software technology industry and am eager to bring my expertise to diverse and challenging projects.</p>

        <div className="about__cards">

        <article className="about__card">
            <FaLaptopCode className="about_icon"/>
            <h5>Technical Skills</h5>
            <small>JavaScript, Python, Swift, C++, Java, Flutter, React, Swift UI, Git, GitHub, GitLab, Xcode, VS Code, Android Studio, Firebase</small>
          </article>  
          
          <article className="about__card" >
            <GoProjectSymlink className="about_icon"/>
            <h5>Projects</h5>
            <small>Undulate Music App, Lyrical Genius App, Doggoland - Dog Breed Info Website, Book My Movie</small>
          </article>  
        <br/>
        
        </div>

          <a href="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default about